<template>
  <Dialog header="Indtast dine kontaktinformationer nedenunder for at downloade din Persona" v-model:visible="displayModal" :style="{width: '50vw'}" :modal="true">
    <span class="p-float-label">
        <InputText id="firstName" type="text" v-model="firstName" :class="{errorFirstName: 'p-invalid'}"/>
        <label for="firstName">Fornavn *</label>
    </span>
    <small id="firstName-help" class="p-error" v-show="errorFirstName">Indtast venligst fornavn.</small>
    <span class="p-float-label">
        <InputText id="lastName" type="text" v-model="lastName" :class="{errorLastName: 'p-invalid'}"/>
        <label for="lastName">Efternavn *</label>
    </span>
    <small id="lastName-help" class="p-error" v-show="errorLastName">Indtast venligst efternavn</small>
    <span class="p-float-label">
        <InputText id="email" type="text" v-model="email" :class="{errorEmail: 'p-invalid'}"/>
        <label for="email">E-mail *</label>
    </span>
    <small id="email-help" class="p-error" v-show="errorEmail">Indtast en gyldig email.</small>
    <span class="p-float-label">
        <InputText id="phone" type="text" v-model="phone" :class="{errorPhone: 'p-invalid'}"/>
        <label for="phone">Telefonnummer *</label>
    </span>
    <small id="phone-help" class="p-error" v-show="errorPhone">Indtast gydlig telefonnummer</small>
    <div class="form_dropdown">
      <div class="form_label">
        <label>Hvad beskriver bedst din jobfunktion? *</label>
      </div>
      <Dropdown id="job-function" v-model="selectedJobFunction" :options="jobFunctions" optionLabel="name" optionValue="name" placeholder="Vælg venligst jobfunktion" />
      <small id="job-function-help" class="p-error" v-show="errorJobFunction">Vælg venligst jobfunktion</small>
    </div>
    <div>
      <p>
        CPH digital ApS har brug for dine kontaktoplysninger for at vi kan kontakte dig angående vores
        produkter og tjenester. Du kan afmelde disse meddelelser til enhver tid. For mere information om,
        hvordan man afmelder, om vores praksis, og hvordan vi er engageret i at beskytte og respektere dit
        privatliv, kan du læse vores <a href="https://www.cphdigital.dk/persondatapolitik/" target="_blank">persondatapolitik</a>.
      </p>
    </div>
    <div>
      <small id="form-help" class="p-error" v-show="errorHubspotForm" v-html="hubspotFormErrorMessage"></small>
    </div>
    <template #footer>
      <Button label="Luk" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
      <Button label="Download" icon="pi pi-check" @click="submitLeadForm" autofocus />
    </template>
  </Dialog>
</template>

<script>
import axios from "axios";
import store from "@/store/store";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";

export default {
  name: "HubspotLeadForm",
  setup() {
    const router = useRouter();

    const displayModal = computed({
      get: () => store.state.displayHubspotForm,
      set: val => {
        store.dispatch(('setDisplayHubspotForm'), {displayModal: val})
      }
    })

    let firstName = ref("")
    let lastName = ref("")
    let company = ref("")
    let email = ref("")
    let phone = ref("")
    let website = ref("")
    let selectedJobFunction = ref("")
    const jobFunctions = [
      {name: 'Andet'},
      {name: 'Grundlægger/Ejer/Partner/Direktør'},
      {name: 'Kommunikationschef'},
      {name: 'Konsulent på et marketing-, reklame- eller kommunikationsbureau'},
      {name: 'Marketing-manager, -koordinator eller lign.'},
      {name: 'Marketingchef eller lign.'},
      {name: 'Salgschef'},
      {name: 'Studerende'},
    ]

    let errorFirstName = ref(false)
    let errorLastName = ref(false)
    let errorCompany = ref(false)
    let errorEmail = ref(false)
    let errorPhone = ref(false)
    let errorWebsite = ref(false)
    let errorJobFunction = ref(false)

    let errorHubspotForm = ref(false)
    let hubspotFormErrorMessage = ref("")

    function closeModal() {
      resetData()
      store.dispatch('setDisplayHubspotForm', {displayModal: false})
    }

    function resetData() {
      firstName.value = "";
      lastName.value = "";
      company.value = "";
      email.value = "";
      phone.value = "";
      website.value = "";
      selectedJobFunction.value = "";

      errorFirstName.value = false;
      errorLastName.value = false;
      errorCompany.value = false;
      errorEmail.value = false;
      errorPhone.value = false;
      errorWebsite.value = false;
      errorJobFunction.value = false;

      errorHubspotForm.value = false
      hubspotFormErrorMessage.value = ""
    }

    function isFormValid(){
      let isValid = true

      if(firstName.value.trim().length < 1){
        errorFirstName.value = true;
        isValid = false
      } else {
        errorFirstName.value = false;
      }
      if(lastName.value.trim().length < 1){
        errorLastName.value = true;
        isValid = false
      } else {
        errorLastName.value = false;
      }
      if(email.value.trim().length < 1){
        errorEmail.value = true;
        isValid = false
      } else {
        errorEmail.value = false;
      }
      if(phone.value.trim().length < 1){
        errorPhone.value = true;
        isValid = false
      } else {
        errorPhone.value = false;
      }
      if(selectedJobFunction.value.trim().length < 1){
        errorJobFunction.value = true;
        isValid = false
      } else {
        errorJobFunction.value = false;
      }

      return isValid
    }

    function createContact(){

      if(isFormValid()) {

        axios({
          method: 'post',
          url: 'https://persona.cphdigital.dk/createContact.php',
          data: {
            company: company.value,
            email: email.value,
            firstname: firstName.value,
            lastname: lastName.value,
            phone: phone.value,
            website: website.value
          }
        }).then((respons) => {

          let status = ""
          if(respons.status) {
            status = respons.status;
          }

          if(status == 'error'){
            //Show error
          } else {
            // generatePdf()
            closeModal()
          }
          console.log(respons)
        });
      }
    }

    function showHubspotFormError(formResponse) {

      if(formResponse.status == "error"){
        errorHubspotForm.value = true;
      } else {
        errorHubspotForm.value = false;
      }

      hubspotFormErrorMessage.value = ""
      if(formResponse.errors.length > 0){
        for(let error of formResponse.errors){

          if(error.errorType == "INVALID_EMAIL"){
            errorEmail.value = true
          } else if(error.message.includes("'fields.phone'")){
            errorPhone.value = true
          } else{
            hubspotFormErrorMessage.value += error.message + "<br />"
          }
        }
      }
    }

    function submitLeadForm(){

      if(isFormValid()) {

        axios({
          method: 'post',
          url: 'https://persona.cphdigital.dk/submitLeadForm.php',
          data: {
            email: email.value,
            firstname: firstName.value,
            lastname: lastName.value,
            phone: phone.value,
            hs_persona: selectedJobFunction.value
          }
        }).then((respons) => {

          let status = ""
          if(respons.data.status) {
            status = respons.data.status
          }

          if(status == 'error'){
            showHubspotFormError(respons.data)
          } else {
            // generatePdf()
            closeModal()
            savePersona()
            // router.push('/saved-persona')
          }
        });
      }
    }

    function savePersona() {
      const persona = {
        personaName: store.state.personaName,
        avatar: store.state.avatar,
        personaAge: store.state.personaAge,
        levelOfSchool: store.state.levelOfSchool,
        industry: store.state.industry,
        sizeOfOrganization: store.state.sizeOfOrganization,
        jobTitle: store.state.jobTitle,
        jobMeasured: store.state.jobMeasured,
        whoReportTo: store.state.whoReportTo,
        goalsOrObjectives: store.state.goalsOrObjectives,
        personaBiggestChallenges: store.state.personaBiggestChallenges,
        jobResponsibilities: store.state.jobResponsibilities,
        personaTools: store.state.personaTools,
        personaCommunicate: store.state.personaCommunicate,
        howGainInformationForJob: store.state.howGainInformationForJob,
        personaSocialNetwork: store.state.personaSocialNetwork
      }

      axios({
        method: 'post',
        url: 'https://persona.cphdigital.dk/savePersona.php',
        data: persona
      }).then((respons) => {
        store.dispatch(('setSavedPersonaId'), respons.data)
        closeModal()
        router.push('/saved-persona')
      });

    }


    return{
      displayModal,
      firstName,
      lastName,
      company,
      email,
      phone,
      website,
      selectedJobFunction,
      jobFunctions,
      errorFirstName,
      errorLastName,
      errorCompany,
      errorEmail,
      errorPhone,
      errorWebsite,
      errorJobFunction,
      closeModal,
      createContact,
      submitLeadForm,
      errorHubspotForm,
      hubspotFormErrorMessage,
      savePersona
    }
  }
}
</script>

<style scoped>
.p-float-label{
  margin-top: 41px;
}

.form_dropdown{
  margin-top: 26px;
}

.form_dropdown .form_label{
  margin-bottom: 10px;
}

.form_dropdown .p-dropdown.p-component{
  width: 100%;
}

</style>