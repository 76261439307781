<template>
  <Card>
    <template #title>
      Information/nyheder/viden til deres job
    </template>
    <template #content>
      <Textarea id="howGainInformationForJob" v-model="howGainInformationForJob" :autoResize="true" rows="5" type="text" placeholder="Indtast tekst her"/>
    </template>
  </Card>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "GainInformationBy",
  setup() {
    const howGainInformationForJob = computed({
      get: () => store.state.howGainInformationForJob,
      set: val => {
        store.dispatch('setHowGainInformationForJob', {description: val})
      }
    })

    return{
      howGainInformationForJob
    }
  }
}
</script>

<style scoped>

</style>