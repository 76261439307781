<template>
    <Card class="social_col">
      <template #title>
        Sociale netværk
      </template>
      <template #content>
        <div class="social_network_list persona_overview">
          <SelectButton v-model="personaSocialNetwork" :options="socialNetworkOptions" optionLabel="name" multiple>
            <template #option="slotProps">
              <div>
                <i :class="slotProps.option.icon"></i>
              </div>
            </template>
          </SelectButton>
        </div>
      </template>
    </Card>
    <Card class="communication_col">
      <template #title>
        Foretrukne måde at kommunikere på
      </template>
      <template #content>
        <div class="communication_list persona_overview">
          <div class="">
            <SelectButton v-model="personaCommunicate" :options="communicateOptions" optionLabel="name" multiple>
              <template #option="slotProps">
                <div>
                  <i :class="slotProps.option.icon"></i>
                  <label>{{slotProps.option.name}}</label>
                </div>
              </template>
            </SelectButton>
          </div>
        </div>
      </template>
    </Card>
</template>

<script>

import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "MethodOfCommunication",
  setup(){

    const communicateOptions = computed( () => store.state.listCommunicate )

    let personaCommunicate = computed({
      get: () => store.state.personaCommunicate,
      set: val => {
        store.dispatch(('setPersonaCommunicate'), {communicate: val})
      }
    })

    const socialNetworkOptions = computed( () => store.state.socialNetworkOptions )
    let personaSocialNetwork = computed({
      get: () => store.state.personaSocialNetwork,
      set: val => {
        store.dispatch(('setPersonaSocialNetwork'), {social: val})
      }
    })

    return{
      communicateOptions,
      personaCommunicate,
      socialNetworkOptions,
      personaSocialNetwork
    }
  }
}
</script>

<style scoped>

.social_col{
  flex: 0 0 22%;
  max-width: 22%;
}

.communication_col{
  flex: 0 0 78%;
  max-width: 78%;
}

.social_network_list{
  margin-top: 12px;
}

::v-deep(.p-buttonset .p-button:first-of-type){
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

::v-deep(.p-selectbutton.p-buttonset .p-button){
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
}

::v-deep(.p-buttonset .p-button:not(:last-child)){
  border-right: 1px solid #ff2a55;
}

::v-deep(.social_network_list .p-buttonset .p-button:first-of-type){
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button){
  color: #8D8D8D;
  padding: 0px;
  border: none;
  border-radius: 0px;
  margin-right: 26px;
  margin-top: 10px;
}
::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button:last-of-type){
  margin-right: 0px;
}

::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button .pi){
  font-size: 22px;
}

::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button .icon-instagram),
::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button .icon-linkedin),
::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button .icon-pinterest){
  font-size: 23px;
}

::v-deep(.social_network_list .p-buttonset .p-button:not(:last-child)){
  border-right: none;
}

::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button.p-highlight){
  border: none;
  background: transparent;
  color: #ff2a55;
}

::v-deep(.social_network_list .p-selectbutton.p-buttonset .p-button:focus){
  border: none;
  box-shadow: none;
}

</style>