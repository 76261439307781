<template>
  <div class="persona_main_info">
    <div class="avatar_col">
      <img class="persona_avatar" alt="persona" :src="`/gfx/avatars/${avatarImg}.svg`" >
    </div>
    <div class="info_col">
      <div class="info_row">
        <div class="info_col_sm">
          <div class="info_field">
            <h3>Navn</h3>
            <InputText type="text" v-model="personaName" placeholder="Indtast teks her" />
          </div>
          <div class="info_field">
            <h3>Alder</h3>
            <InputText type="text" v-model="age" placeholder="Indtast teks her" />
          </div>
        </div>
        <div class="info_col_md">
          <div class="info_field">
            <h3>Branche</h3>
            <InputText type="text" v-model="selectedIndustry" placeholder="Indtast teks her" />
          </div>
          <div class="info_field">
            <h3>Jobtitel</h3>
            <InputText type="text" v-model="jobTitle" placeholder="Indtast teks her" />
          </div>
        </div>
        <div class="info_col_md">
          <div class="info_field">
            <h3>Højeste uddannelse</h3>
            <InputText type="text" v-model="levelOfSchool" placeholder="Indtast teks her" />
          </div>
          <div class="info_field">
            <h3>Størrelsen af deres organisation</h3>
            <InputText type="text" v-model="sizeOfOrganization" placeholder="Indtast teks her" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "PersonaMainInfo",
  setup(){
    const avatarImg = computed( () => {
      if(store.state.avatar){
        return store.state.avatar
      } else {
        return 'avatar_blond_guy'
      }
    })

    const personaName = computed({
      get: () => store.state.personaName,
      set: val => {
        store.dispatch(('setPersonaName'), {name: val})
      }
    });

    const jobTitle = computed({
      get: () => store.state.jobTitle,
      set: val => {
        store.dispatch(('setJobTitle'), {title: val})
      }
    })

    const age = computed({
      get: () => store.state.personaAge,
      set: val => {
        store.dispatch(('setPersonaAge'), {age: val})
      }
    })

    const levelOfSchool = computed({
      get: () => store.state.levelOfSchool,
      set: val => {
        store.dispatch(('setLevelOfSchool'), {level: val})
      }
    })

    const selectedIndustry = computed({
      get: () => store.state.industry,
      set: val => {
        store.dispatch(('setIndustry'), {industry: val})
      }
    })

    const sizeOfOrganization = computed({
      get: () => store.state.sizeOfOrganization,
      set: val => {
        store.dispatch(('setSizeOfOrganization'), {size: val})
      }
    })



    return{
      avatarImg,
      personaName,
      jobTitle,
      age,
      levelOfSchool,
      selectedIndustry,
      sizeOfOrganization
    }

  }
}
</script>

<style scoped>
.persona_main_info{
  display: flex;
  width: 100%;
  padding-top: 1rem;
}

.persona_main_info .avatar_col{
  flex: 0 0 15%;
  max-width: 15%;
}

.persona_main_info .info_col{
  flex: 0 0 85%;
  max-width: 85%;
}

.persona_main_info .info_row{
  display: flex;
  margin: 0px -20px;
}

.persona_main_info .info_col_sm{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0px 20px
}

.persona_main_info .info_col_md{
  flex: 0 0 37.5%;
  max-width: 37.5%;
  padding: 0px 20px
}

.persona_main_info .persona_avatar{
  display: block;
  max-width: 200px;
  margin: 0px auto;
}

.persona_main_info h3{
  font-size: 20px;
  margin-bottom: 14px;
}

.persona_main_info .info_field{
  margin-bottom: 36px;
}

</style>
