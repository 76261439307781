<template>
  <Card>
    <template #title>
      Deres job bliver målt på
    </template>
    <template #content>
      <Textarea id="jobResponsibilities" v-model="jobMeasured" :autoResize="true" rows="5" type="text" placeholder="Indtast tekst her"/>
    </template>
  </Card>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "JobMeasuredBy",
  setup() {
    const jobMeasured = computed({
      get: () => store.state.jobMeasured,
      set: val => {
        store.dispatch(('setJobMeasured'), {measured: val})
      }
    })

    return{
      jobMeasured
    }
  }
}
</script>

<style scoped>

</style>