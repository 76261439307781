<template>
  <QuestionHeader></QuestionHeader>
  <hubspot-lead-form></hubspot-lead-form>
  <div>
    <div>
      <h1>Persona-oversigt</h1>
      <div>
        <div class="overview_header_row">
          <div class="overview_subheader">
            <h2>Buyer persona, som hele din virksomhed kan bruge</h2>
          </div>
          <div class="overview_tools">
            <Button label="Download/Export" @click="openHubspotForm" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div ref="personaOverview">
    <div class="persona_overview_info">
      <div class="main_info">
        <persona-main-info></persona-main-info>
      </div>
      <Divider></Divider>
      <div class="additional_info_communication">
        <method-of-communication></method-of-communication>
      </div>
      <Divider></Divider>
      <div class="additional_info">
        <div class="info_card">
          <job-responsibilities></job-responsibilities>
        </div>
        <div class="info_card">
          <job-measured-by></job-measured-by>
        </div>
        <div class="info_card">
          <reports-to></reports-to>
        </div>
        <div class="info_card">
          <goals-or-objects></goals-or-objects>
        </div>
        <div class="info_card">
          <gain-information-by></gain-information-by>
        </div>
      </div>
      <Divider></Divider>
      <div class="additional_info_checklist">
        <div class="info_card">
          <biggest-challenges></biggest-challenges>
        </div>
        <div class="info_card">
          <tools-need-do-job></tools-need-do-job>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue"
import store from "@/store/store";
import { useRoute } from 'vue-router';

import PersonaMainInfo from "@/components/personaOverviewCards/PersonaMainInfo";
import MethodOfCommunication from "@/components/personaOverviewCards/MethodOfCommunication";
import ToolsNeedDoJob from "@/components/personaOverviewCards/ToolsNeedDoJob";
import JobResponsibilities from "@/components/personaOverviewCards/JobResponsibilities";
import JobMeasuredBy from "@/components/personaOverviewCards/JobMeasuredBy";
import ReportsTo from "@/components/personaOverviewCards/ReportsTo";
import GoalsOrObjects from "@/components/personaOverviewCards/GoalsOrObjectives";
import GainInformationBy from "@/components/personaOverviewCards/GainInformationBy";
import BiggestChallenges from "@/components/personaOverviewCards/BiggestChallenges";
import HubspotLeadForm from "@/components/HubspotLeadForm";
import QuestionHeader from "@/components/QuestionHeader";

export default {
name: "PersonaOverview",
  components: {
    QuestionHeader,
    HubspotLeadForm,
    BiggestChallenges,
    GainInformationBy,
    GoalsOrObjects,
    ReportsTo,
    JobMeasuredBy,
    JobResponsibilities,
    ToolsNeedDoJob,
    MethodOfCommunication,
    PersonaMainInfo},
  setup() {
    const personaOverview = ref(null)
    const route = useRoute();
    onMounted(() => {
       if(route.params.personaId) {
        axios({
          method: 'get',
          url: 'https://persona.cphdigital.dk/getPersona.php',
          params: {
            persona_id: route.params.personaId
          }
        }).then((respons) => {
          if(respons.data.persona_data) {
            let persona = JSON.parse(respons.data.persona_data)
            updatePersonaState(persona)
          }
        });
      }
    })

    function updatePersonaState(persona){
      store.dispatch('setSavedPersonaData', persona)
    }

    function openHubspotForm() {
      store.dispatch('setDisplayHubspotForm', {displayModal: true})
    }

    return{
      personaOverview,
      openHubspotForm
    }
  }
}
</script>

<style scoped>
.persona_overview_info{
  display: block;
}

.persona_overview_info .main_info{
}

.persona_overview_info .additional_info{
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.persona_overview_info .additional_info .info_card{
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0px 20px 10px 20px;
}

.persona_overview_info .additional_info .info_card .p-card{
  height: 100%;
}

.persona_overview_info .additional_info_communication{
  display: flex;
}

.persona_overview_info .additional_info_checklist{
  display: flex;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 60px;
}

.persona_overview_info .additional_info_checklist .info_card{
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0px 40px 20px 40px;
}

::v-deep(.persona_overview_info .additional_info_checklist .p-inputtext){
  max-width: 420px;
}

.overview_header_row{
  display: flex;
  justify-content: space-between;
}

.overview_tools{
  text-align: right;
  padding-right: 10px;
  margin-bottom: 10px;
}
</style>