<template>
  <Card>
    <template #title>
      Mål eller fokus
    </template>
    <template #content>
      <Textarea id="goalsOrObjectives" v-model="goalsOrObjectives" :autoResize="true" rows="5" type="text" placeholder="Indtast tekst her"/>
    </template>
  </Card>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "GoalsOrObjects",
  setup() {

    const goalsOrObjectives = computed({
      get: () => store.state.goalsOrObjectives,
      set: val => {
        store.dispatch(('setGoalsOrObjectives'), {goalsOrObjects: val})
      }
    })

    return{
      goalsOrObjectives
    }
  }
}
</script>

<style scoped>

</style>