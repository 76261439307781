<template>
  <Card>
    <template #title>
      Jobansvar
    </template>
    <template #content>
      <Textarea id="jobResponsibilities" v-model="jobResponsibilities" :autoResize="true" rows="5" type="text" placeholder="Indtast tekst her"/>
    </template>
  </Card>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "JobResponsibilities",
  setup() {
    const jobResponsibilities = computed({
      get: () => store.state.jobResponsibilities,
      set: val => {
        store.dispatch(('setJobResponsibilities'), {responsibilities: val})
      }
    })

    return{
      jobResponsibilities
    }
  }
}
</script>

<style scoped>
</style>